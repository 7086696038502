@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .card{
    width: theme('width.full');
    height: theme('height.full');
    padding: 0.7rem;
    background: #802665ab;  
  }
    
  .cardH{
    font-size: theme('fontSize.sm');
    font-weight: theme('fontWeight.bold');
  }
.my-container{
  background: linear-gradient(white, #fbcfe8 );
  padding-top: 4rem;
  padding-bottom: 3rem;
  padding: 2.25rem;
}

  .uiux-box, .webdev-box, .motion-box, .branding-box{
    background-color: theme('backgroundColor.white');
    padding: 0 2rem;
    border: 1px solid #6D0550 ;
  }

  .uiux-box h1, .webdev-box h1, .motion-box h1, .branding-box h1{
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    padding-top: 0.5rem;
  }
  
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
