*{
    padding: 0;
    margin: 0;
}

.contents_img{
  margin: 0 auto;
  object-fit: cover;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* transition: background-color 0.3s ease-in-out; */
    z-index: 1000;
  }

  .myshadow{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  .nav-hidden{
    transition: transform 0.3s ease;
    transform: translateY(-175%);
  }
  .navbar.scrolling {
    /*background-color: #fff2fb;  Change the background color when scrolling */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a shadow when scrolling */
    transition: transform 0.5s ease;
    transform: translateY(0);
  }

  .show-border {
    border-color: transparent!important;
  }

  .typewriter1 {
    overflow: hidden;
    border-right: 0.2rem solid orange;
    white-space: nowrap;
    animation: typing 2s steps(19, end), blink-caret .75s step-end infinite;
  }
  
  @keyframes typing {
    0%, 50%{
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .typewriter2{
    overflow: hidden;
    border-right: 0.2rem solid transparent;
    white-space: nowrap;
    animation: typing2 4s steps(24, end), blink-caret .75s step-end 2.5s infinite;
  }

  @keyframes typing2 {
    0%, 50% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  .typewriter3{
    overflow: hidden;
    border-right: 0.2rem solid transparent;
    white-space: nowrap;
    animation: typing3 6s steps(24, end), blink-caret .75s step-end 3s infinite;
  }

  @keyframes typing3 {
    0%, 50%, 70% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }

.branding{
    background-image: url(../assets/gif/brandGif.gif);
}
.writing{
    background-image: url(../assets/gif/writingGif.gif);
}
.uiux{
    background-image: url(../assets/gif/uiGif.gif);
}
.webapp{
    background-image: url(../assets/gif/webGif.gif);
}
.motion{
    background-image: url(../assets/gif/motionGif.gif);
}
.business{
    background-image: url(../assets/gif/businessGif.gif);
}
.hover-div img{
    transition: opacity 0.3s ease;
}
.hover-div p {
    font-size: 0.9rem;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease;
}
.hover-div .hover-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-div:hover .hover-content{
    opacity: 1;
}

.mysvg {
    top: 0;
    left: 0;
    width: 100%;
    height: 13rem;
    box-sizing: border-box;
    display: block;
}

.bg1{
    background-image: url('../assets/bg1.png');
    background-color: #df5fbb;
}
.bg2{
    background-image: url('../assets/service.png');
}
.bg3{
    background-image: url('../assets/about.png');
}
.bg-img{
    background-image: url('../assets/csn.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.bg-img2{
    background-image: url('../assets/megride2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.bg-img-dark{
    background-image: url('../assets/darkimg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.bg-img2-dark{
    background-image: url('../assets/darkimg2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.toast-message {
    font-style: italic;
    font-weight: 600;
    color: black;
}
.darken-image {
    position: relative;
    display: inline-block;
 }
 .darken-image img {
    display: block;
 }
 .darken-image .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
 }
 .cardP{
    top: 50%;
    padding: 0.7rem;
    background: #ffe9f6b8;
    font-size: theme('fontSize.sm');
  }


  .logobg{
    background-image: url(../assets/logobg.png);
  }

  .myhtml h1{
    font-weight: 700;
    font-size: 1.125rem;
  }

  @media screen and (max-width: 1275px){
    .video{
        width: 16rem;
    }
  }

 @media screen and (max-width: 1018px){
    .bg2{
        background-image: none;
    }
    
}

@media screen and (max-width: 750px){
    .cardP{
        top: 50%;
        padding: 0;
        background: #ffe9f6b8;
        font-size: 0.7rem;
      }

    .bg-img{
        background-image: url('../assets/darkimg1.png');
        background-repeat: no-repeat;
        background-size: 23rem 100%;
    }
    .bg-img2{
        background-image: url('../assets/darkimg2.png');
        background-repeat: no-repeat;
        background-size: 23rem 100%;
    }
    .bg-img-dark{
        background-image: url('../assets/darkimg1.png');
        background-repeat: no-repeat;
        background-size: 23rem 100%;
    }
    .bg-img2-dark{
        background-image: url('../assets/darkimg2.png');
        background-repeat: no-repeat;
        background-size: 23rem 100%;
    }
    .typewriter1 {
      overflow: hidden;
      border-right: none!important;
      white-space: nowrap;
      animation: typing 2s steps(19, end), blink-caret2 .75s step-end infinite;
    }
    .typewriter2{
      overflow: hidden;
      border-right: 0.2rem solid none;
      white-space: nowrap;
      animation: typing2 4s steps(24, end), blink-caret2 .75s step-end 2.5s infinite;

    }
    .typewriter3{
      overflow: hidden;
      border-right: 0.2rem solid none;
      white-space: nowrap;
      animation: typing3 6s steps(24, end), blink-caret2 .75s step-end 3s infinite;
    }
    .mytrans{
      transition: visibility 3s ease;
      visibility: visible;
    }
    .mytrans2{
      transition: visibility 3s ease-in;
      visibility: hidden;
    }

  @keyframes blink-caret2 {
    from, to {
      border-color: none;
    }
    50% {
      border-color: none;
    }
  }

}
@media screen and (max-width: 525px){
    .myflex{
        display: flex;
    }
    .myflex p{
        padding: 0.6rem;
    }
    
}
@media screen and (max-width: 490px){
    .hover-content{
        display: none!important;
    }
    
}
