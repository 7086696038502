.splide__list{
    gap: 0.3rem;
}
.splide__arrow{
    top: -2rem!important;
}
.splide__arrow--prev{
    left: 90%!important;
}
.splide__arrow svg{
    fill: #6D0550!important;
}
.splide__arrow{
    border: 2px solid #6D0550!important;
    background: none!important;
}

@media screen and (max-width: 750px){
    .splide__arrow--prev{
        left: 80%!important;
    }   
    .splide__list{
        column-gap: 0.9rem!important;
    }
}
@media screen and (max-width: 450px){
    .splide__arrow--prev{
        left: 77%!important;
    }   
}